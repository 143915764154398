import { createContext,  useEffect, useState } from 'react';
import Banner from "./components/banner/Banner";
import Call from "./components/call/Call";
import Header from "./components/header/Header";
import Product from "./components/production/Product";
import Questions from "./components/questions/Questions";
import Service from "./components/service/Service";
import Why from "./components/why/Why";
import classes from "././components/header/header.module.scss";
import Price from "./components/price/Price";
import Footer from './components/footer/Footer';

export const AppContext = createContext(null);

function App() {
  // const phoneNumber ="8 (499) 551-01-89";
  const phoneNumber ="8 (495) 972-71-71"; 
  const [price, setPrice] = useState(false)
  const [focus, setFocus] = useState(false);
	const [focusName, setFocusName] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
//Верх страницы - для кнопки прокрутки - что бы наверху ее не было
  useEffect(() => {
    function handleScroll() {
      setIsAtTop(window.scrollY === 0);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

/******/
const documentElement = document.documentElement;
//прокрутка наверх по кнопке
  const scrollToTop = () => {
    documentElement.scrollTo({ top: 0, behavior: 'smooth' });//плавно
  };

//прокрутка наверх при переходе со страницы на страницу
  const scrollToTopQuik = () => {
    documentElement.scrollTo({ top: 0}); //без плавности
  };
/*****/
	const [error, setError] = useState('');
	const [value, setValue] = useState("");
  const [modalopen, setModalopen]=useState(false)

  function openModal () {
      setModalopen(true);
      setPrice(false);
      document.body.style.overflow = 'hidden';
  }
	
	function nameSetting(event) {
		 event && setError(event.trim().length<12)
	 }
 useEffect(()=>{
    nameSetting(value)
  },[value])

  return (
    <>
    {!isAtTop && <button className= {classes.up_btn} onClick={()=>{scrollToTop()}} > <div className={classes.arrow}><img  src={require("./chevron-up.webp")} alt="arrow"></img></div> </button>}
    {price ? <Price setPrice={setPrice} scrollToTopQuik={scrollToTopQuik} />
    :(
    
    <AppContext.Provider value={{ 
        phoneNumber:phoneNumber,
        error:error, 
        nameSetting: nameSetting,
        modalopen: modalopen,
        setModalopen:setModalopen, 
        openModal:openModal,
        focus:focus,
        setFocus:setFocus,
        focusName:focusName,
        setFocusName:setFocusName,
        value:value,
        setValue: setValue,
        price: price,
        // setPrice:setPrice,
        // scrollToTopQuik:scrollToTopQuik
   }}>
    
      <Header />
    <main>
      <Banner/>
      <Service />
      <Why/>
      <Call phoneNumber={phoneNumber}/>
      <Product/>
      <Questions/>
    </main>
    <Footer setPrice={setPrice} scrollToTopQuik= {scrollToTopQuik}/>
     </AppContext.Provider>
     )
}</>
  );
}
export default App


